// {
//   "_id": "AT",
//   "index": 0,
//   "name": "Aiste Tiskeviciute",
//   "title": "Junior Developer",
//   "headshot": "Aiste.png",
//   "cv": "./cv/Aiste_Tiskeviciute.pdf",
//   "quote": "How wonderful it is that nobody need wait a single moment before starting to improve the world. - Anne Frank",
//   "bio": "A hard-working individual with an aptitude and love for learning. I am easily approachable with an inclination to push myself. This can be seen in me joining a 12-week intensive Software Development program at _Nology, allowing me to bring real-life client web-designs into fruition via code. I'd be sure to bring endless curiosity and boundless enthusiasm to any team.",
//   "linkedin": "https://www.linkedin.com/in/aiste-tiskeviciute-760b9b1bb/",
//   "github": "https://github.com/AisteTsk",
//   "portfolio": "https://aiste-portfolio.web.app/"
// }

const data = {
  trainees: [
    {
      _id: "AS",
      index: 0,
      name: "Alice Scott",
      title: "Junior Developer",
      headshot: "Alice_Scott.png",
      cv: "./cv/Alice_Scott.pdf",
      quote: "Shoot for the moon. Even if you miss you'll land among the stars.",
      bio:
        "I am a junior software developer seeking a challenging role in which I can continue to learn and improve on the skills I have developed so far. I have a diverse range of experience, most recently working as a front-line police officer in Westminster where I greatly progressed my communication skills and adaptiveness whilst working efficiently within a team. The past 12 weeks have shown me how rewarding a career in tech can be and I am excited for the opportunity to start the next chapter.",
      linkedin: "linkedin.com/in/alice-s-a93762105 ",
      github: "https://github.com/acys17",
      portfolio: "https://alice-scott.co.uk",
    },
    {
      _id: "CA",
      index: 1,
      name: "Ciprian Andrei",
      title: "Junior Developer",
      headshot: "Ciprian_Andrei.png",
      cv: "./cv/Ciprian_Andrei.pdf",
      quote:
        "If something is important enough, even if the odds are stacked against you, you should still do it. – Elon Musk",
      bio:
        "Former Bar Manager with a passion for coding and web design decided to make the jump towards a career change. I started with FreeCodeCamp and CodeCademy where I have learned HTML, CSS and Javascript and now I have just completed a 12 Week Software Developer Bootcamp organised by _nology and I am excited for the future job opportunities where I can showcase my talent.",
      linkedin: "https://www.linkedin.com/in/ciprian-andrei-8381231b9/",
      github: "https://github.com/Ciprian-A",
      portfolio: "https://ciprian-andrei-wd.co.uk",
    },
    {
      _id: "EB",
      index: 2,
      name: "Eddie Bassey",
      title: "Junior Developer",
      headshot: "Edet_Bassey.png",
      cv: "./cv/Edet_Bassey.pdf",
      quote: "Tomorrow belongs to the people who prepare for it today.",
      bio:
        "I have a passion for software development and my aspiration is to build innovative applications for robust user-experiences. As a Junior Software Developer I want to expand on my current tech stack and also I am open to learn new programming languages to broaden my knowledge and advance my career.",
      linkedin: "https://www.linkedin.com/in/edet-bassey-50392b193/",
      github: "https://github.com/eddiebass",
      portfolio: "https://eddiebassey.co.uk",
    },
    {
      _id: "EI",
      index: 3,
      name: "Ezio Intravaia",
      title: "Junior Developer",
      headshot: "Ezio_Intravaia.png",
      cv: "./cv/Ezio_Intravaia.pdf",
      quote: "Find a job you enjoy doing, and you will never have to work a day in your life. ― Mark Twain",
      bio:
        "I have always known tech was the career path I wanted to follow. Finally I had the chance to make it happen. As soon as I started coding I knew it was for me; I found out I am naturally good at it and when I code I get so excited I cannot stop. Now after _nology I feel I've gained the right skills to bring it to the next level by starting my first role!",
      linkedin: "https://www.linkedin.com/in/ezio-intravaia/",
      github: "https://github.com/Ezio2994",
      portfolio: "https://intravaiaezio.co.uk/",
    },
    {
      _id: "JF",
      index: 4,
      name: "Josh Foster",
      title: "Junior Developer",
      headshot: "Josh_Foster.png",
      cv: "./cv/Josh_Foster.pdf",
      quote: "Winning doesn’t always mean being first. Winning means you’re doing better than you’ve done before.",
      bio:
        "I’m a First-Class Math’s graduate who enjoys being creative and learning new ways to solve challenging problems. I have found that Software Development is a great way to express both of these qualities, and subsequently embarking on a 12-week intensive course has been one of the best decisions I've made. I have a background in Graphic Design and Animation, and enjoy DJing in my spare time.",
      linkedin: "https://www.linkedin.com/in/josh-foster-a66094142/",
      github: "https://github.com/josh-foster",
      portfolio: "http://joshfoster.co.uk/",
    },
    {
      _id: "KT",
      index: 5,
      name: "Kirin Thapar",
      title: "Junior Developer",
      headshot: "Kirin_Thapar.png",
      cv: "./cv/Kirin_Thapar.pdf",
      quote: "Do not judge me by my successes, judge me by how many times I fell down and got back up again.",
      bio:
        "Hard working and orgainsed team player, who loves tech and to solve problems. I have just completed a intensive 12 week bootcamp and now looking for a junior software development role.",
      linkedin: "https://www.linkedin.com/in/kirin-thapar-266a5bb1/",
      github: "https://github.com/kirint93",
      portfolio: "https://kirinthapar.co.uk",
    },
    {
      _id: "MW",
      index: 6,
      name: "Malwina Wower",
      title: "Junior Developer",
      headshot: "Malwina_Wower.png",
      cv: "./cv/Malwina_Wower.pdf",
      quote: "The harder you work for something, the greater you'll feel when you achieve it",
      bio:
        "I am a driven and committed technology enthusiast embarking on becoming a Software Developer. Eager to learn and naturally curious, I enjoy solving problems, organising projects and working as a team.  ",
      linkedin: "https://www.linkedin.com/in/malwina-wower-23768b200/",
      github: "https://github.com/wowermalwina/",
      portfolio: "http://malwinawower.co.uk/",
    },
    {
      _id: "MS",
      index: 7,
      name: "Mike Sanins",
      title: "Junior Developer",
      headshot: "Mike_Sanins.png",
      cv: "./cv/Mike_Sanins.pdf",
      quote:
        "Until you make the unconscious conscious, it will direct your life and you will call it fate. - C.G. Jung",
      bio:
        "I’m ready to embark into the world of tech as a junior web developer. As a creative, inquisitive and reliable person, my personal attributes go hand in hand with the tangible skills learnt on the _nology 12 week bootcamp. Development is a very rewarding profession and i’m keen to get started.",
      linkedin: "https://www.linkedin.com/in/mike-sanins-702b0150/",
      github: "https://github.com/mikes-cpu",
      portfolio: "https://sanins.co.uk",
    },
    {
      _id: "OP",
      index: 8,
      name: "Otis Parsons",
      title: "Junior Developer",
      headshot: "Otis_Parsons.png",
      cv: "./cv/Otis_Parsons.pdf",
      quote: "Do, or do not. There is no try. - Yoda",
      bio:
        "A Technology, Finance & Start-up enthusiast. Currently training as a Software Engineer with Nology.io to further pursue my passion. Previously held leadership Operational & Product roles within the Impact Investing, Fintech, Spatial Data & Computer vision technology companies. \n Key Languages & Frameworks: Javascript | React | HTML & CSS | Key Software: Github | Git | Excel | Salesforce | Looker ",
      linkedin: "https://www.linkedin.com/in/otis-parsons/",
      github: "https://github.com/otisparsons",
      portfolio: "https://oparsons.com/",
    },
    {
      _id: "SP",
      index: 9,
      name: "Sachin Patel",
      title: "Junior Developer",
      headshot: "Sachin_Patel.png",
      cv: "./cv/Sachin_Patel.pdf",
      quote: "Live as if you were to die tomorrow. Learn as if you were to live forever. — Mahatma Gandhi",
      bio:
        "Hi, I'm Sach. I pride myself on being a relaxed, sociable and enthusiastic developer looking to enhance my skills, and learn more via joining and contributing to a progressive and innovative team. Looking for a challenging, inclusive and fulfilling role. Excited to move into the tech industry!",
      linkedin: "https://www.linkedin.com/in/sachin-patel-48409b142/",
      github: "https://github.com/sachin6699",
      portfolio: "https://sachin-patel.co.uk/",
    },
    {
      _id: "ST",
      index: 10,
      name: "Stefan Tudorache",
      title: "Junior Developer",
      headshot: "Stefan_Tudorache.png",
      cv: "",
      quote: "I know not all that may be coming, but be it what it will, I’ll go to it laughing.",
      bio:
        'My name is Stefan and I am the sum of my failures. I have learned more from my mistakes than from my successes. I never leave a failure to be just that. It\'s a journey of discovery that will teach me why it\'s not good to do that again and at its best will turn a failure into a success. This is who I am, be it in my personal life or at a workplace. This is not to say that I won\'t fail again, but I embrace it because I will learn again.',
      linkedin: "https://www.linkedin.com/in/stefan-tudorache-46a9351b4/",
      github: "https://github.com/Stefan-Tudorache?tab=repositories",
      portfolio: "https://stefanwebdev.com/",
    },
    {
      _id: "TS",
      index: 0,
      name: "Tim Simonson",
      title: "Junior Developer",
      headshot: "Tim_Simonson.png",
      cv: "./cv/Timothy_Simonson.pdf",
      quote: "In the midst of winter, I found there was, within me, an invincible summer. - Camus",
      bio:
        "Striking the perfect balance between being a hard-worker and a hard-learner, I thoroughly enjoy being faced with challenges to solve, problems, and learning new skills. I've had a love for all things tech for a while, and I'm looking forward to this next move into a career in the industry! I'm thoroughly motivated working both within a team, as we have done at _Nology, as much as I enjoy working on my own projects.",
      linkedin: "https://www.linkedin.com/in/tim-simonson-6513441b1/",
      github: "https://github.com/ttytango",
      portfolio: "https://tjsimonson.co.uk",
    },
  ],
};

export default data;
